import { container } from "tsyringe";
import { ShopifyDataMapper } from "@/data/persistences/mappers/ShopifyDataMapper";
import { ShopifyPresenter } from "@/app/ui/presenters/ShopifyPresenter";
import { ShopifyApiRepository } from "@/app/infrastructures/repositories/api/ShopifyApiRepository";
import ApiServiceMiddleware from "@/app/infrastructures/services/ApiServiceMiddleware";
import { ShopifyEndpoint } from "@/app/infrastructures/endpoints/ShopifyEndpoint";

export class ShopifyComponent {
  public static init() {
    container.register<ShopifyApiRepository>(ShopifyApiRepository, {
      useFactory: d => {
        return new ShopifyApiRepository(
          d.resolve(ApiServiceMiddleware),
          d.resolve(ShopifyDataMapper),
          d.resolve(ShopifyEndpoint)
        );
      }
    });
    container.register<ShopifyDataMapper>(ShopifyDataMapper, {
      useClass: ShopifyDataMapper
    });
    container.register<ShopifyPresenter>(ShopifyPresenter, {
      useFactory: d => {
        return new ShopifyPresenter(d.resolve(ShopifyApiRepository));
      }
    });
  }
}
