import { ShopifyTokenData } from "@/domain/entities/Shopify";
import { AxiosResponse } from "axios";

export class ShopifyDataMapper {
  public convertShopifyGenerateDataFromApi(
    result: AxiosResponse<any>
  ): ShopifyTokenData {
    const { data } = result;

    return new ShopifyTokenData({
        clientKey: data.data.client_key,
        clientSecret: data.data.client_secret,
        isExpiredClientCredential: data.data.is_expired_client_credential?.toLowerCase() === "yes"
    });
  }
}
