import { injectable } from "tsyringe";
import { ShopifyApiRepository } from "@/app/infrastructures/repositories/api/ShopifyApiRepository";
import { ShopifyTokenData } from "@/domain/entities/Shopify";
import { Method } from "axios";
@injectable()
export class ShopifyPresenter {
  private repository: ShopifyApiRepository;

  constructor(repository: ShopifyApiRepository) {
    this.repository = repository;
  }

  public generateToken(method: Method): Promise<ShopifyTokenData> {
    return this.repository.generateToken(method);
  }
}
