/* eslint-disable @typescript-eslint/camelcase */

import {
    Action,
    getModule,
    Module,
    VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { ShopifyPresenter } from "@/app/ui/presenters/ShopifyPresenter";
import { Method } from "axios";
import { ShopifyComponent } from "@/app/infrastructures/dependencies/modules/ShopifyComponent";
ShopifyComponent.init();

@Module({ namespaced: true, dynamic: true, store, name: "shopify" })
class ShopifyStore extends VuexModule {
    @Action
    public generateToken(method: Method) {
        const presenter = container.resolve(ShopifyPresenter);
        return presenter.generateToken(method);
    }
}

export const ShopifyController = getModule(ShopifyStore);
