import { ShopifyDataMapper } from "@/data/persistences/mappers/ShopifyDataMapper";
import { ShopifyRepositoryInterface } from "@/data/persistences/repositories/contracts/ShopifyRepositoryInterface";
import { ShopifyTokenData } from "@/domain/entities/Shopify";
import ApiServiceMiddleware from "../../services/ApiServiceMiddleware";
import { ShopifyEndpoint } from "@/app/infrastructures/endpoints/ShopifyEndpoint";
import { Method } from "axios";

export class ShopifyApiRepository implements ShopifyRepositoryInterface {
  private service: ApiServiceMiddleware;
  private mapper: ShopifyDataMapper;
  private endpoints: ShopifyEndpoint;

  constructor(
    service: ApiServiceMiddleware,
    mapper: ShopifyDataMapper,
    endpoints: ShopifyEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async generateToken(method: Method): Promise<ShopifyTokenData> {
    const resp = await this.service.invoke(
      method,
      this.endpoints.generateToken(),
      {}
    );
    return this.mapper.convertShopifyGenerateDataFromApi(resp);
  }
  
}
